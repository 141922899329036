.range-datepicker-popover {
  --bs-popover-body-padding-y: 0;
  --bs-popover-body-padding-x: 0;
  --bs-popover-arrow-width: 0;
  --bs-popover-arrow-height: 0;
  --bs-popover-max-width: 675px;
  --bs-popover-border-width: 0;
  --bs-popover-border-radius: 16px;

  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
}
