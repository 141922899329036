.modal {
  --bs-modal-header-padding: 1rem 1.5rem;
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
}

.modal-md {
  --bs-modal-width: 660px;
}

.modal-height-lg {
  display: flex;
  min-height: 80vh;
  max-height: 80vh;

  .modal-body{
    display: flex;
    flex: 1;
  }
}
