@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    outline: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
    outline: none;
    border-radius: var(--bs-border-radius);
  }
}

@mixin custom-scrollbar-wide {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border: none;
    outline: none;
    border-left: 1px solid var(--bs-border-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--bs-primary);
    outline: none;
    border-radius: 4px;
  }
}
