// This is for personalised reader only since we are sharing core component,
// Please do not add any variables in the list, instead customize bootstrap variables based on theme
:root {
  --bg-reader: var(--bs-body-bg);
  --text-color: var(--bs-body-color);
  --mix-blend-mode:       lighten;
}

//card
$card-padding-width:      20px;
$card-padding-x:          20px;
$card-padding-y:          20px;
$card-padding-x-mobile:   15px;
$card-padding-y-mobile:   15px;
$grid-gutter-width:       16px;

$font-size_tiny:          0.71rem;
$font-size_small:         0.85rem;
$font-size_global:        1rem;
$font-size_middle:        1.14rem;
$font-size_large:         1.28rem;

$line-height_global:      1.6;
$block-padding-mobile:    15px;

// Form
$form-input-height:       40px;

// Fonts
$montserrat: 'Montserrat', Arial, 'Helvetica Neue', Helvetica, sans-serif;
$league-spartan: 'League Spartan', Arial, 'Helvetica Neue', Helvetica, sans-serif;

  // Bootstrap Variables Overrides
$body-bg:                 #FFF;
$body-color:              #000;
$primary:                 #283378;
$secondary:               #777777;
$success:                 #1F8922;
$danger:                  #D70000;
$warning:                 #FFB328;
$info:                    #F46B30;
$light:                   #D4D6E4;
$dark:                    #212529;
$grey:                    #A1A1A1;
$light-grey:              #ECECEC;
$yellow:                  #FFD600;
$blue:                    #227FA8;
$purple:                  #895B9E;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "grey":       $grey,
  "light-grey": $light-grey,
  "blue":       $blue,
  "purple":     $purple
);


$font-family-base:                  $montserrat;
$font-weight-base:                  400;
$small-font-size:                   0.75rem;
$font-size-semi-small:              0.875rem;
$btn-font-weight:                   600;
$badge-border-radius:               4px;
$badge-padding-y:                   6px;
$badge-padding-x:                   5px;
$badge-font-weight:                 500;
$progress-height:                   8px;
$nav-link-font-weight:              600;
$nav-link-padding-y:                0.4rem;
$nav-underline-link-active-color:   $primary;
$nav-link-color:                    $secondary;
$border-radius:                     0.5rem;
$border-radius_lg:                  0.75rem;
$border-color:                      $grey;
$dropdown-border-radius:            0.75rem;
$modal-content-border-radius:       1rem;
$modal-inner-padding:               1.5rem;
$dropdown-item-padding-y:           0.5rem;
$form-range-track-height:           0.25rem;
$form-range-track-bg:               $light-grey;
$form-range-thumb-width:            0.75rem;

$table-border-color:                $light;

//Floating label
$form-floating-height:                  3.5rem;
$form-floating-label-transform:         scale(.75) translateY(-.5rem) translateX(0.25rem);
