ngb-datepicker {
  .ngb-dp-header {
    background-color: unset;
  }

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-month-name {
    background-color: unset;
    font-size: 0.75rem;
    color: var(--bs-secondary);
    font-weight: 600;
  }

  .ngb-dp-week {
    justify-content: space-between;
  }

  .ngb-dp-weekdays {
    border-bottom: none !important;
    background-color: unset !important;
  }

  .ngb-dp-weekday {
    font-weight: 600;
    color: var(--bs-body-color) !important;
    font-style: normal !important;
  }

  .ngb-dp-day.disabled {
    opacity: 0.5;
  }

  .ngb-dp-today > div {
    background-color: var(--bs-light);
  }

  [ngbDatepickerDayView] {
    width: 100% !important;
    height: 100% !important;
    border-radius: 50% !important;
    align-content: center;
  }

  &.wider {

    .ngb-dp-month-name {
      font-size: 1rem;
    }

    .ngb-dp-weekday {
      font-size: 1rem;
    }

    .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
      width: 3rem !important;
      height: 3rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.inline {
    border: none !important;
  }
}

.range-datepicker {
  width: 100%;

  border: none;

  .ngb-dp-header {
    padding: 32px;
    padding-bottom: 16px;
  }

  .ngb-dp-footer {
    padding: 32px;
    padding-top: 0;
  }

  .ngb-dp-navigation-select {
    order: 0;
    margin-inline-end: auto;
    flex: 0;
    flex-basis: 300px;


    .form-select {
      --bs-form-select-bg-img: none;
      flex: 0;
      padding: 0;

      font-weight: 600;
      font-size: 16px;
      color: var(--bs-secondary);
      border: none;
      pointer-events: none;

      &:first-child {
        flex-basis: 60px;
      }

      &:last-child {
        flex-basis: 60px;
      }
    }
  }

  .ngb-dp-arrow {
    flex: 0;
  }

  .ngb-dp-arrow-prev {
    order: 1;
  }

  .ngb-dp-arrow-next {
    order: 1;
  }

  .ngb-dp-arrow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0;

    &:hover .ngb-dp-navigation-chevron {
      color: var(--bs-black);
    }

    .ngb-dp-navigation-chevron {
      margin: 0;
      color: var(--bs-secondary);
    }
  }

  .ngb-dp-weekday {
    font-size: 16px;
  }

  .ngb-dp-day,
  .ngb-dp-weekday {
    width: 100%;
    height: 48px;
  }

  .ngb-dp-day {
    position: relative;
  }

  .ngb-dp-day:has(.custom-day.faded) {
    background-color: var(--bs-light);
  }

  .ngb-dp-day:has(.custom-day.range) + .ngb-dp-day:has(.custom-day.faded),
  .ngb-dp-day:has(.custom-day.faded) + .ngb-dp-day:has(.custom-day.range),
  .ngb-dp-day:has(.custom-day.range) + .ngb-dp-day:has(.custom-day.range), {
    &::after {
      content: '';

      position: absolute;
      z-index: -1;
      top: 0;
      transform: translateX(-50%);

      width: 100%;
      height: 100%;

      background-color: var(--bs-light);
    }
  }
}
