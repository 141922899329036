.card {
  $self: &;
  border: none;

  &--shadow {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
  }

  &-header,
  &-body,
  &-footer {
    background: transparent;
    padding: $card-padding-x-mobile $card-padding-x-mobile;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $card-padding-y $block-padding-mobile 0;
    border: none;

    .icon {
      max-height: 30px;
    }

    .card-title {
      margin: 0;
      font-size: $font-size_global;
      font-weight: 500;
      line-height: 1.4;
      transition: color .25s;
      text-decoration: none;

      small,
      .small {
        display: inline-block;
        white-space: nowrap;
        font-size: $font-size_small;
        font-weight: 500;
      }

      .link-more {
        font-size: $font-size_tiny;
      }
    }

    &--border {
      padding-bottom: 25px;
      border-bottom: 1px solid var(--border-color);
    }

    &.bg-primary {
      padding-bottom: $card-padding-y;
    }
  }

  &-body {
    .card-title {
      margin-top: 0;
      margin-bottom: .7em;
    }

    + .card-header {
      padding-top: 15px;
      border-top: 1px solid var(--border-color);
    }
  }

  &-footer {
    border-color: var(--border-color);

    &.no-border {
      border: none;
    }
  }

  &-title {
    font-size: $font-size_global;
    line-height: $line-height_global;

    @include media-breakpoint-up(md) {
      font-size: $font-size_middle;
    }
  }

  &-small {
    .card-body {
      padding: 20px 15px;
    }
  }

  &--collapse {
    .btn-arrow {
      width: 20px;
      height: 20px;
    }

    .card-header {
      padding-bottom: $card-padding-width;

      + .card-body {
        padding-top: 0;
      }
    }

    .accordion {
      .card-header {
        .btn-link {
          min-height: auto;

          .btn-arrow {
            top: 17px
          }
        }
      }

      .card-body {
        padding-top: 0;
      }
    }
  }

  &--collapsed {
    .btn-arrow {
      &::before {
        top: 2px;
        transform: rotate(225deg);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &-body,
    &-footer {
      padding: $card-padding-y $card-padding-x;
    }

    &-header {
      padding: $card-padding-width $card-padding-width 0;

      &--border {
        margin-bottom: 5px;
        padding-bottom: 20px;
      }

      .card-title {
        font-size: $font-size_middle;
      }

      &.bg-primary {
        padding-bottom: $card-padding-y;
      }
    }

    &-small {
      .card-body {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &-navigation-border {
      margin: 15px (-$card-padding-width) 0;
      padding: 15px $card-padding-width 0;
    }
  }

  @include media-breakpoint-up(lg) {
    &-header {
      + .card-body {
        padding-top: 16px;
      }
    }
  }

  &__transparent {
    background: transparent;
    box-shadow: none;
  }
}

.book-card {
  --bs-card-border-radius: 1.5rem;

  padding: 0;
  flex-direction: row;

  cursor: pointer;
}
