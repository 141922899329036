.ag-chart-tooltip {
  box-shadow: 0 4px 16px rgba(0,0,0,0.15);
  border-radius: 0.5rem;
  overflow: hidden;
  font-family: var(--bs-body-font-family) !important;

  .ag-chart-tooltip-content {
    border: none;
  }
}
