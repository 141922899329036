.full-width {
  --bs-tooltip-max-width: 100% !important;
}

.half-width {
  --bs-tooltip-max-width: 60% !important;
}

.half-width .tooltip-inner {
  text-align: justify !important;
}
