.epub-view {
  display: table !important;
}

.scrolled-mode .epub-container {
  height: unset !important;
  overflow-x: hidden !important;
}

.cloze-assessment-pending {
  stroke: var(--bs-primary) !important;
  stroke-width: 2;
  stroke-opacity: 1.0 !important;
  pointer-events: bounding-box;
  cursor: pointer;

  &:hover {
    stroke-width: 3;
    -webkit-filter: drop-shadow( 0 4px 4px rgba(40, 51, 120, 0.5));
    filter: drop-shadow( 0 4px 4px rgba(40, 51, 120, 0.5));
  }

  @include media-breakpoint-up(md) {
    scroll-margin-top: 100px;
  }

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 70px;
  }

  rect {
    fill: var(--bs-body-bg) !important;
    pointer-events: none;
    rx: 6px;

    &:hover {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
    }
  }

  app-school-client & {
    rect {
      fill: var(--bs-body-bg) !important;
    }
  }

  line {
    stroke: none !important;
    pointer-events: none;
  }
}

.cloze-assessment-correct {
  stroke: none !important;
  stroke-opacity: 1.0;

  rect {
    fill: none !important;
    pointer-events: none;
  }
  line {
    stroke: var(--bs-green) !important;
    stroke-width: 4;
    pointer-events: none;

  }
}

.cloze-assessment-incorrect {
  stroke: none !important;
  stroke-opacity: 1.0;

  rect {
    fill: none !important;
    pointer-events: none;
  }
  line {
    stroke: var(--bs-danger) !important;
    stroke-width: 4;
    pointer-events: none;
  }
}

.bookmark-annotation {
  stroke: var(--bs-red) !important;
  stroke-opacity: 1.0 !important;
  stroke-width: 2 !important;
  fill-opacity: 1;

  rect {
    fill: none;
    stroke: none !important;
  }
  .outline {
    fill: none;
    stroke-linejoin: round;
  }
  line {
    stroke-width: 5 !important;
  }
}

.quotation-annotation {
  stroke: var(--bs-danger) !important;
  stroke-opacity: 1.0 !important;
  stroke-width: 2 !important;
  fill-opacity: 1;

  rect {
    fill: none;
    stroke: none !important;
  }
  .outline {
    fill: none;
    stroke-linejoin: round;
  }
  line {
    stroke-width: 5 !important;
  }
}

.cloze-assessment-active {
  stroke: var(--bs-primary) !important;
  stroke-opacity: 1.0 !important;
  stroke-width: 3 !important;
  -webkit-filter: drop-shadow( 0 4px 4px rgba(40, 51, 120, 0.5));
  filter: drop-shadow( 0 4px 4px rgba(40, 51, 120, 0.5));
  outline: none;

  rect {
    fill: var(--bs-body-bg) !important;
    rx: 6px;
  }
  line {
    stroke: none !important;
  }
}

.bookmark {
  stroke: var(--bs-green) !important;
  stroke-opacity: 1.0 !important;
  stroke-width: 1 !important;

  rect {
    fill: transparent !important;
  }
  line {
    stroke: none !important;
  }
}

.fill-assessment-pending {
  stroke: var(--bs-gray-800) !important;
  stroke-width: 2;
  stroke-opacity: 1.0 !important;
  pointer-events: bounding-box;
  cursor: pointer;

  rect {
    fill: var(--bs-yellow) !important;
    pointer-events: none;
  }
  line {
    stroke: none !important;
    pointer-events: none;
  }
}

.fill-assessment-create {
  stroke: var(--bs-yellow);
  stroke-opacity: 1.0;
  stroke-width: 2;

  rect {
    fill: transparent;
  }
  line {
    stroke: none;
  }
}

.thought-annotation-1 {
  stroke: #ff99ff;
  stroke-opacity: 0.3;

  rect {
    fill: #ff99ff;
    fill-opacity: 0.3;
  }
  line {
    stroke: none;
  }
}

.thought-annotation-2 {
  stroke: #99ccff;
  stroke-opacity: 0.3;

  rect {
    fill: #99ccff;
    fill-opacity: 0.3;
  }
  line {
    stroke: none;
  }
}

.thought-annotation-3 {
  stroke: #ffff00;
  stroke-opacity: 0.3;

  rect {
    fill: #ffff00;
    fill-opacity: 0.3;
  }
  line {
    stroke: none;
  }
}

.thought-annotation-4 {
  stroke: var(--bs-yellow);
  stroke-opacity: 0.3;

  rect {
    fill: var(--bs-yellow);
    fill-opacity: 0.3;
  }
  line {
    stroke: none;
  }
}

.thought-annotation-5 {
  stroke: #ff9999;
  stroke-opacity: 0.3;

  rect {
    fill: #ff9999;
    fill-opacity: 0.3;
  }
  line {
    stroke: none;
  }

}

.thought-annotation-6 {
  stroke: var(--bs-primary);
  stroke-opacity: 0.3;

  rect {
    fill: var(--bs-primary);
    fill-opacity: 0.3;
  }
  line {
    stroke: none;
  }
}

.citation-annotation {
  stroke-opacity: 0.3;
  &--fcd63f {
    fill: var(--bs-yellow);
    stroke: var(--bs-yellow);
    rect {
      fill: inherit;
      fill-opacity: 0.3;
    }
    line {
      stroke: none;
    }
  }
  &--4fd752 {
    fill: #4fd752;
    stroke: #4fd752;
    rect {
      fill: inherit;
      fill-opacity: 0.3;
    }
    line {
      stroke: none;
    }
  }
  &--5bbafb {
    fill: #5bbafb;
    stroke: #5bbafb;
    rect {
      fill: inherit;
      fill-opacity: 0.3;
    }
    line {
      stroke: none;
    }
  }
  &--fb739a {
    fill: #fb739a;
    stroke: #fb739a;
    rect {
      fill: inherit;
      fill-opacity: 0.3;
    }
    line {
      stroke: none;
    }
  }
  &--ac8fef {
    fill: #ac8fef;
    stroke: #ac8fef;
    rect {
      fill: inherit;
      fill-opacity: 0.3;
    }
    line {
      stroke: none;
    }
  }
}

.listen-reader-highlight {
  rect {
    fill-opacity: 1;
    mix-blend-mode: var(--mix-blend-mode);
  }
}

.listen-reader-highlight-animate {
  rect {
    animation: reveal 0.5s linear forwards;
    fill-opacity: 1;
    mix-blend-mode: var(--mix-blend-mode);
  }
}

.rtl .listen-reader-highlight-animate rect {
  animation-name: reveal_reverse;
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes reveal_reverse {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.listen-reader-highlight-fade {
  rect {
    fill-opacity: 0.7 !important;
    fill: var(--bs-body-bg) !important;
  }
}

.part-of-speech-highlight {
  &__ADJECTIVE {
    @extend .btn-outline-control-green !optional;
    @extend .text-control-green !optional;
    stroke: var(--bs-green);
  }
  &__ADVERB {
    @extend .btn-outline-control-gold !optional;
    @extend .text-control-gold !optional;
    stroke: var(--bs-yellow);
  }
  &__CONJUNCTION {
    @extend .btn-outline-control-aqua !optional;
    @extend .text-control-aqua !optional;
    stroke: var(--bs-cyan)
  }
  &__INTERJECTION {
    @extend .btn-outline-control-rose !optional;
    @extend .text-control-rose !optional;
    stroke: var(--bs-code-color);
  }
  &__NOUN {
    @extend .btn-outline-control-purple !optional;
    @extend .text-control-purple !optional;
    stroke: var(--bs-purple);
  }
  &__PRONOUN {
    @extend .btn-outline-control-lightblue !optional;
    @extend .text-control-lightblue !optional;
    stroke: var(--bs-teal);
  }
  &__PROPER_NOUN {
    @extend .btn-outline-control-pink !optional;
    @extend .text-control-pink !optional;
    stroke: var(--bs-pink);
  }
  &__VERB {
    @extend .btn-outline-control-turquoise !optional;
    @extend .text-control-turquoise !optional;
    stroke: var(--bs-primary-text-emphasis);
  }
}

.syllables-highlight {
  &__1 {
    @extend .btn-outline-control-purple !optional;
    @extend .text-control-purple !optional;
    stroke: var(--bs-purple) !important;
    line {
      stroke: none;
    }

  }
  &__2 {
    @extend .btn-outline-control-lightblue !optional;
    @extend .text-control-lightblue !optional;
    stroke: var(--bs-primary-text-emphasis);
    line {
      stroke: none;
    }
  }
  &__3 {
    @extend .btn-outline-control-pink !optional;
    @extend .text-control-pink !optional;
    stroke: var(--bs-pink) !important;
    line {
      stroke: none;
    }
  }
  &__4 {
    @extend .btn-outline-control-rose !optional;
    @extend .text-control-rose !optional;
    stroke: var(--bs-code-color) !important;
    line {
      stroke: none;
    }
  }
  &__5 {
    @extend .btn-outline-control-gold !optional;
    @extend .text-control-gold !optional;
    stroke: var(--bs-yellow) !important;
    line {
      stroke: none;
    }
  }
  &__6 {
    @extend .btn-outline-control-mint !optional;
    @extend .text-control-mint !optional;
    stroke: var(--bs-teal) !important;
    line {
      stroke: none;
    }
  }
  &__7 {
    @extend .btn-outline-control-coral !optional;
    @extend .text-control-coral !optional;
    stroke: var(--bs-danger) !important;
    line {
      stroke: none;
    }
  }
}

.part-of-speech--practice-highlight {
  &__ADJECTIVE {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-green);
  }
  &__ADVERB {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-yellow);
  }
  &__CONJUNCTION {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-cyan);
  }
  &__INTERJECTION {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-code-color);
  }
  &__NOUN {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-purple);
  }
  &__PRONOUN {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-blue);
  }
  &__PROPER_NOUN {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-pink);
  }
  &__VERB {
    @extend .word-to-practice-highlight !optional;
    fill: var(--bs-teal);
  }
}

.word-to-practice-identify-active {
  stroke-opacity: 1.0 !important;
  stroke-width: 2 !important;
  stroke: var(--bs-purple) !important;

  rect {
    fill: white !important;
  }
  line {
    stroke: none !important;
  }
}

.word-to-practice-identify-pending {
  stroke: #333 !important;
  stroke-width: 2;
  stroke-opacity: 1.0;
  pointer-events: bounding-box;
  fill: var(--bs-purple) !important;

  rect {
    fill: inherit;
    pointer-events: none;
  }
  line {
    stroke: none !important;
    pointer-events: none;
  }
}

.word-to-practice-highlight {
  fill-opacity: 0.3;
  pointer-events: bounding-box;

  rect {
    fill: inherit;
    pointer-events: none;
  }

  line {
    stroke: none;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
}

.grammar-rule-highlight {
  @extend .word-to-practice-highlight !optional;
  fill: var(--bs-pink);
}

.grammar-rule-highlight-pending {
  @extend .word-to-practice-highlight !optional;
  fill: var(--bs-pink);
  fill-opacity: 1;
}

.grammar-rule-highlight-active {
  stroke-opacity: 1.0 !important;
  stroke-width: 2 !important;
  stroke: var(--bs-pink) !important;

  rect {
    fill: white !important;
  }
  line {
    stroke: none !important;
  }
}

.listen-reader-frame {
  stroke-opacity: 1.0;
  stroke-width: 4;

  rect {
    fill: transparent;
  }
  line {
    stroke: none;
  }
}

.reader-assessment-highlight {
  stroke: var(--bs-blue);
  stroke-opacity: 1.0;
  stroke-width: 2;

  rect {
    stroke: none;
  }

  line {
    stroke: var(--bs-yellow) !important;
    stroke-width: 2;
    pointer-events: none;
  }
}

.spelling-bee-highlight {
  stroke-opacity: 0;
  stroke-width: 1;
  pointer-events: fill;
  cursor: pointer;
  fill-opacity: 1;

  rect {
    stroke: none;
    fill: none;
  }
  line {
    stroke: none;
  }
}

.video-clip-highlight {
  stroke-opacity: 0;
  stroke-width: 1;
  pointer-events: fill;
  cursor: pointer;

  rect {
    fill: var(--bs-primary);
    fill-opacity: 1;
    mix-blend-mode: var(--mix-blend-mode);
  }
  line {
    stroke-opacity: 1;
    stroke: var(--bs-primary);
  }
}

.picture-dictionary-highlight {
  stroke-opacity: 0;
  stroke-width: 1;
  pointer-events: fill;
  cursor: pointer;

  rect {
    fill: var(--bs-primary);
    fill-opacity: 1;
    mix-blend-mode: var(--mix-blend-mode);
  }
  line {
    stroke-opacity: 1;
    stroke: var(--bs-primary);
    stroke-dasharray: 3 4;
  }
}

.indicator-highlight {
  fill: var(--bs-green);
  fill-opacity: 0.3;
  rect {
    height: 2px;
    width: 2px;
    fill: var(--bs-green);
  }
  line {
    stroke: none;
  }
}
