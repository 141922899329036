.offcanvas {

  &.offcanvas-end {
    box-shadow: -4px 0 16px 0 rgba(0,0,0,0.1);
    border-left: none;
  }

  &.wide {
    --bs-offcanvas-width: 700px;
  }
}

.offcanvas-backdrop {
  &.clear {
    opacity: 0;
  }
}
