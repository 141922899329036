h1 {
  font-family: $league-spartan;
  font-weight: 700;
}

.fs-semi-small {
  font-size: $font-size-semi-small;
}

app-personalised-reader-core {
  small, .small {
    font-size: 0.75em;
  }
}
