.nav-pills {
  &__wrapped {
    background-color: $light-grey;
    border-radius: var(--bs-nav-pills-border-radius);
  }
}

.nav-underline {
  align-items: baseline;

  &.small {
    --bs-nav-underline-border-width: 0.25rem;
    --bs-nav-underline-link-active-color: var(--bs-body-color);

    .nav-link {
      --bs-nav-link-padding-y: 0;

      font-size: 1rem;
      font-weight: 400;

      &.active {
        font-weight: 600;
        border-bottom-color: var(--bs-primary);
      }
    }
  }

  &.large {
    --bs-nav-underline-border-width: 0.375rem;

    .nav-link {
      font-family: $league-spartan;
      font-size: 28px;
      font-weight: 500;
      height: 56px;

      &.active {
        font-size: 32px;
        font-weight: 600;
      }
    }
  }
}
