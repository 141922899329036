@use 'sass:math';

.page-header {
  position: relative;
  margin: 0;
  padding: 10px 0;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 40px;
  }

  &__title {
    @extend h3;
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 0;
    font-family: $league-spartan;
    font-weight: 600;
  }

  @include media-breakpoint-up(md) {
    padding: 0 0 1.5rem;
  }
}

.page-content {
  flex-grow: 1;
  position: relative;
}

.page-title {
  display: block;
  margin: 0 0 62px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.page-body {
  flex-grow: 1;
  flex-basis: 0;

  @include media-breakpoint-up(lg) {
    width: calc(100% - 300px - 16px);

    &.page-body--wide {
      width: 100%;
    }
  }

  @include media-breakpoint-up(dt) {
    &--wide {
      max-width: 1000px;
    }
  }

  @include media-breakpoint-up(ldt) {
    width: calc(100% - 450px - 16px);
  }
}
