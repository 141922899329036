.ng-select {
  &:not(.form-select) {
    width: 100%;
    min-width: 140px;
    min-height: 40px;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1;
    color: rgb(var(--bs-secondary-rgb));

    border-radius: 8px;
    border: 1px solid transparent;

    background-color: #ececec;
    transition: box-shadow 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

    &.ng-select-disabled {
      opacity: 0.65;
    }

    &:not(.ng-select-disabled):hover,
    &:not(.ng-select-disabled).ng-select-focused {
      background-color: #efefef;
      border-color: var(--bs-primary);
      box-shadow: 0 0 0 1px #eeeeee;
    }
  }

  &.week-selector .ng-option {
    padding: 0;
  }

  &.ng-select-single.wrap-selected .ng-select-container .ng-value-container .ng-value {
    white-space: normal;
  }

  &.ng-select-outline-secondary {
    font-weight: 400;
    font-size: 1rem;
    background-color: var(--bs-body-bg);
    border-color: var(--bs-secondary);

    &:hover,
    &:not(.ng-select-disabled).ng-select-focused {
      background-color: var(--bs-body-bg);
      border-color: var(--bs-primary);
      box-shadow: 0 0 0 1px #eeeeee;
    }
  }

  .ng-arrow-wrapper .ng-arrow {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .ng-select-container {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 38px;
    padding: 0 12px;

    &.ng-has-value {
      .ng-placeholder {
        display: none;
      }
    }
  }

  .ng-placeholder {
    color: var(--bs-grey);
  }

  .ng-value-container {
    align-items: center;
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        width: 100%;

        .ng-input {
          padding: 0 12px;
          padding-inline-end: 24px;
        }

        .ng-value-label {
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }

  .ng-clear-wrapper {
    width: 25px;
    color: var(--bs-primary);

    .ng-clear {
      font-size: 26px;
      line-height: 0.8;
      margin-left: 5px;
    }
  }

  &.form-select {
    .ng-arrow-wrapper {
      display: none;
    }
  }
}

.form-floating > .form-select {
  .ng-select-container {
    min-height: auto;
    padding: 0;
  }

  .ng-dropdown-panel {
    margin-top: 10px;
  }
}

$dropdown-border-radius: 12px;

// Override ng-select own style selector specificity to allow text wrapping of text within ng-option
ng-dropdown-panel.ng-dropdown-panel {
  .ng-dropdown-panel-items .ng-option {
    white-space: normal;
  }

  .ng-dropdown-header {
    padding: 10px 20px 10px 12px;
    font-weight: normal;
    font-size: $font-size_global;
    line-height: 1.2;
    background: transparent;
    transition: 0.15s ease-in-out;
    color: #000000;
  }
}

.ng-dropdown-panel {
  left: 0;
  margin-top: 1px;
  background: #ffffff;
  border: 1px solid var(--bs-border-color);
  border-radius: $dropdown-border-radius;
  overflow: hidden;

  .ng-option, .ng-optgroup {
    padding: 10px 12px;
    font-weight: normal;
    font-size: $font-size_global;
    line-height: 1.2;
    background: transparent;
    transition: 0.15s ease-in-out;
    color: #000000;

    &:not(.ng-option-marked):hover {
      color: var(--bs-white);
      background-color: var(--bs-primary);
    }

    &-label {
      white-space: normal;
    }
  }

  .ng-optgroup {
    font-weight: 600;
  }

  .ng-option-marked {
    background-color: rgba(18, 85, 159, 0.2) !important;
  }

  .ng-option-selected {
    color: #ffffff !important;
    background-color: var(--bs-primary) !important;
  }

  .ng-option-disabled {
    opacity: 0.5;
  }

  .scroll-host {
    @include custom-scrollbar;
  }
}

.ng-select-check {
  .ng-spinner-loader {
    margin-right: 25px;
  }

  > .ng-dropdown-panel > .ng-dropdown-panel-items {
    scrollbar-gutter: stable;

    > div {
      margin-right: calc(-0.5 * var(--bs-gutter-x));
    }

    .ng-option, .ng-optgroup {
      padding-right: 21px;
    }

    .ng-option-selected {
      color: #000000 !important;
      background-color: transparent !important;
    }
  }
}
