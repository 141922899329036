.range {
  display: block;

  &-row {
    .ngx-slider {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .ngx-slider {
    margin: 0;

    &.animate {
      .ngx-slider-pointer,
      .ngx-slider-bar-wrapper {
        transition: all .1s linear;
      }
    }

    .ngx-slider-bar-wrapper {
      margin-top: -12px;
      padding-top: 12px;
      height: 30px;
    }

    .ngx-slider-pointer {
      top: -7px;
      width: 20px;
      height: 20px;
      background-color: var(--bs-primary);
      box-shadow: 0 0 0 1px var(--bs-body-bg);
      &.focus,
      &:focus {
        outline: -webkit-focus-ring-color double 4px;
      }

      .overlay-filter & {
        box-shadow: 0 0 0 1px var(--bs-gray-700);
      }

      &:hover {
        outline: none;
      }

      &::after {
        display: none;
      }
    }

    .ngx-slider-bar {
      height: 6px;
      background: var(--bs-gray-300);
      border-radius: 3px;
    }

    .ngx-slider-selection {
      background: var(--bs-primary-text-emphasis);
    }

    .ngx-slider-bubble {
      bottom: 12px;
      font-size: 0.85rem;

      &.ngx-slider-limit {
        color: var(--bs-body-color);
      }
    }
  }

  &_sm {
    .ngx-slider {
      .ngx-slider-pointer {
        top: -5px;
        width: 14px;
        height: 14px;
      }

      .ngx-slider-bar {
        height: 4px;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;

    svg {
      flex-shrink: 0;
      margin-right: 10px;

      path {
        fill: var(--bs-body-color);
      }
    }
  }
}
