.btn {
  font-size: $font-size_global;
  line-height: 1.6;

  &:hover {
    box-shadow: 0 0 0 1px var(--bs-btn-hover-border-color);
  }

  &:not(.btn-colored-icon) {
    path,
    rect,
    circle {
      fill: currentColor;
    }
  }

  svg {
    width: auto;
    flex-shrink: 0;
  }

  &:focus,
  &.focus {
    outline: revert;
  }

  &.disabled,
  &:disabled {
    &:not(.btn-link) {
      --bs-btn-disabled-color: var(--bs-dark-grey);
      --bs-btn-disabled-bg: var(--bs-light-grey);
      --bs-btn-disabled-border-color: var(--bs-light-grey);

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;

    span {
      padding-left: 6px;
    }
  }
}

.btn-shadow {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}
