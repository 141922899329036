// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}e#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-inline-end: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}s#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-inline-start: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if "#{$size}" != "0" {
        .m#{$infix}-n#{$size} { margin: -$length !important; }
        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        .me#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-inline-end: -$length !important;
        }
        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        .ms#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-inline-start: -$length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto { margin: auto !important; }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .me#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-inline-end: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ms#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-inline-start: auto !important;
    }
  }
}

.mb {
  margin-bottom: $block-padding-mobile;

  @include media-breakpoint-up(md) {
    margin-bottom: 16px;
  }
}

.mt {
  margin-top: $block-padding-mobile;

  @include media-breakpoint-up(md) {
    margin-top: 16px;
  }
}

.mr {
  margin-right: $block-padding-mobile;

  @include media-breakpoint-up(md) {
    margin-right: $grid-gutter-width;
  }
}
