[class*=ag-theme-] {
  --ag-font-family: $font-family-base;
  --ag-checkbox-checked-color: var(--bs-primary);

  .ag-header-cell.cell-center .ag-header-cell-label {
    justify-content: center;
  }

  .ag-header-cell.cell-end .ag-header-cell-label {
    justify-content: flex-end;
  }

  .ag-header-cell.cell-wrap .ag-header-cell-text {
    white-space: normal;
  }

  .ag-header-cell.no-grow {
    .ag-header-cell-label {
      flex-grow: 0;
    }

    .ag-header-cell-menu-button {
      margin-inline-end: auto;
    }
  }

  .ag-cell-wrap-text {
    word-break: break-word;
  }

  .ag-menu {
    border-radius: 0.75rem;
    box-shadow: var(--bs-box-shadow);
  }
}

.ag-report-builder-grid {
  .ag-header-cell-menu-button {
    margin-inline-start: 0.5rem;
  }

  .ag-filter-icon {
    display: none;
  }

  .ag-cell-label-container:has(.ag-filter-icon:not(.ag-hidden)) .ag-header-cell-menu-button .ag-icon-menu {
    opacity: 0.54;
    background: transparent url('../../assets/images/shared/filter-list.svg') center/contain no-repeat;
    color: transparent;
  }

  .ag-filter-apply-panel {
    border-top: 1px solid var(--bs-border-color);
  }
}
