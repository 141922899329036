/* You can add global styles to this file, and also import other style files */
@import "assets/scss/variables";
@import "assets/scss/theming";
@import "assets/scss/mixins";

/* Importing Custom Bootstrap SCSS Import file. */
@import 'assets/scss/bootstrap';



// bootstrap overrides
@import "assets/scss/bootstrap-overrides/card";
@import "assets/scss/bootstrap-overrides/forms";
@import "assets/scss/bootstrap-overrides/spacing";
@import "assets/scss/bootstrap-overrides/grid";
@import "assets/scss/bootstrap-overrides/btn";
@import "assets/scss/bootstrap-overrides/reboot";
@import "assets/scss/bootstrap-overrides/nav";
@import "assets/scss/bootstrap-overrides/table";
@import "assets/scss/bootstrap-overrides/dropdown";
@import "assets/scss/bootstrap-overrides/modal";
@import "assets/scss/bootstrap-overrides/datepicker";
@import "assets/scss/bootstrap-overrides/offcanvas";
@import "assets/scss/bootstrap-overrides/tooltips";
@import "assets/scss/bootstrap-overrides/popover";

// Custom scss
@import "assets/scss/page";

// Other scss overrides
@import "assets/scss/overrides-ng-select";
@import "assets/scss/overrides-ngx-slider";

// Reader scss Override
@import "assets/scss/reader/overrides-epubjs";

// Ag-grid
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "assets/scss/overrides-ag-grid";

// Ag-chart
@import "assets/scss/overrides-ag-chart";

// Swiper
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/scrollbar';

// Global styles
html,
body {
  font-size: 14px;
  height: 100%;

  @include media-breakpoint-up(lxdt) {
    font-size: 16px;
  }
}
