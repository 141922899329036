.form-group {
  position: relative;
  display: block;
  margin-bottom: 20px;

  &_submit {
    margin-top: 30px;
  }

  &.row {
    .form-label {
      margin-bottom: 0;
    }
  }

  &-value {
    display: flex;
    align-items: baseline;

    .form-control {
      width: 80px;
    }
  }

  &-time {
    .form-control {
      width: 40px;
      margin-right: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    ng-select.ng-select {
      width: 65px;
    }
  }

  &-tooltip {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    app-tooltip-info {
      flex-shrink: 0;
      margin: 0 0 14px 10px;
    }

    .form-group {
      flex-grow: 1;
      margin-bottom: 0;
    }
  }
}

.form-label {
  display: block;
  margin: 0 0 2px;
  font-size: 14px;
  font-weight: normal;

  &.position-absolute {
    bottom: 100%;
  }
}

.form-control {
  font-weight: 500;
  &:not(:disabled):not(.ng-select-disabled):not([readonly]):hover {
    border-color: var(--bs-primary);
  }

  &:focus {
    box-shadow: none;
    border-color: var(--bs-primary);
  }

  &::placeholder {
    color: var(--bs-grey);
  }

  &--search {
    padding-right: 32px;
    background-image: url("/assets/images/icons/search-dark.svg");
    background-position: calc(100% - 4px) 50%;
    background-repeat: no-repeat;
  }

  &--transparent {
    &,
    &:focus,
    &:hover,
    &:active {
      border-color: transparent;
      background: transparent;
    }
  }

  &.hidden {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: none;
  }

  &.ng-invalid.ng-dirty {
    border-color: var(--bs-danger);
  }
}

.form-check {
  padding-left: 1.8em;

  .form-check-input {
    margin-left: -1.8em;
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.15em;
  }
}

.form-check-reverse {
  padding-right: 1.8em;
  padding-left: 0;

  .form-check-input {
    margin-right: -1.8em;
    margin-left: 0;
  }
}

.form-switch .form-check-input {
  width: 2.5em;
  height: 1.5em;
}

.number {
  position: relative;
  width: 100%;

  input[type=number] {
    width: 100%;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    color: var(--bs-body-color);
    background: transparent;
    -moz-appearance: textfield;
    outline: none;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &-up,
  &-down {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--bs-body-color);
    opacity: .5;
    transition: opacity .2s ease-out;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 10px;
      height: 2px;
      background: var(--bs-body-bg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.disabled {
      cursor: default;
      opacity: .25;
      pointer-events: none;
    }

    &:hover:not(.disabled) {
      opacity: .75;
    }
  }

  &-up {
    right: 1px;
  }

  &-down {
    left: 1px;

    &::after {
      display: none;
    }
  }
}

.form-floating {
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-control.ng-select-searchable.ng-select-focused,
  > .form-select {
    ~ label {
      font-weight: 600;
    }

    &::after {
      display: none;
    }
  }

  > .form-select:not(:has(.ng-value)) ~ label,
  > label {
    transform: none;

    font-weight: 400;
    color: var(--bs-grey);
  }

  > .form-control.ng-select-searchable.ng-select-focused ~ label {
    color: rgba(var(--#{$prefix}body-color-rgb), #{$form-floating-label-opacity});
    transform: $form-floating-label-transform;
  }

  .ng-invalid.ng-dirty ~ label {
    color: var(--bs-danger);
  }
}
